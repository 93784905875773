import i18next from 'i18next'

const locale = 'sv-SE'

const getMonthName = (date: Date): string => {
  return date.toLocaleString(locale, { month: 'short' })
}

export const getFullMonth = (date: Date): string => {
  return date.toLocaleString(locale, { month: 'long' })
}

export const getDayOfWeekIndex = (date: Date): number => {
  switch (date.getDay()) {
    case 0:
      return 6
    case 1:
      return 0
    case 2:
      return 1
    case 3:
      return 2
    case 4:
      return 3
    case 5:
      return 4
    case 6:
      return 5
    default:
      return 0
  }
}

export const getDaysFromNow = (date: Date): number => {
  const today = new Date()
  const milliSecDiff = today.getTime() - date.getTime()
  const convertToDays = 1000 * 60 * 60 * 24
  return Math.ceil(milliSecDiff / convertToDays)
}

export const datesAreOnSameDay = (first: Date, second: Date) => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  )
}

export const formatDate = (date: Date): string => {
  return `${date.getDate()} ${getMonthName(date)} ${date.getFullYear()}`
}

export const formatDateMonthYear = (date: Date): string => {
  return date.toLocaleDateString(locale, { month: 'long', year: 'numeric' })
}

export const formatDateYYYYMMDD = (date: Date): string => {
  return date.toLocaleDateString(locale)
}

export const getHalfYearAgoDate = (date: Date): string => {
  date.setMonth(date.getMonth() - 6)
  return formatDateYYYYMMDD(date)
}

export const getTimeAgo = (date: Date, format: 'short' | 'long' = 'long'): string => {
  const todayDate = new Date()

  const oneMonthAgo = new Date()
  oneMonthAgo.setDate(todayDate.getDate() - 30)

  const oneWeekAgo = new Date()
  oneWeekAgo.setDate(todayDate.getDate() - 7)

  const oneDayAgo = new Date()
  oneDayAgo.setDate(todayDate.getDate() - 1)

  const oneHourAgo = new Date()
  oneHourAgo.setHours(todayDate.getHours() - 1)

  const oneMinuteAgo = new Date()
  oneMonthAgo.setMinutes(todayDate.getMinutes() - 1)

  const isMoreThanOneMonthAgo = oneMonthAgo > date
  const isMoreThanOneWeekAgo = oneWeekAgo > date
  const isMoreThanOneDayAgo = oneDayAgo > date
  const isMoreThanOneHourAgo = oneHourAgo > date
  const isMoreThanOneMinuteAgo = oneMinuteAgo > date

  if (isMoreThanOneMonthAgo) {
    return format === 'short'
      ? i18next.t('commons:more_than_one', { unit: i18next.t('commons:time.month') })
      : formatDate(date)
  } else if (isMoreThanOneWeekAgo) {
    const numberOfWeeksAgo = getWeeksAgo(date)
    return i18next.t(`commons:time.week${format === 'short' ? '_short' : ''}`, { count: numberOfWeeksAgo })
  } else if (isMoreThanOneDayAgo) {
    const numberOfDaysAgo = getDaysAgo(date)
    return i18next.t(`commons:time.day${format === 'short' ? '_short' : ''}`, { count: numberOfDaysAgo })
  } else if (isMoreThanOneHourAgo) {
    const numberOfHoursAgo = getHoursAgo(date)
    return i18next.t(`commons:time.hour${format === 'short' ? '_short' : ''}`, { count: numberOfHoursAgo })
  } else if (isMoreThanOneMinuteAgo) {
    const numberOfMinutesAgo = getMinutesAgo(date)
    return i18next.t(`commons:time.minute${format === 'short' ? '_short' : ''}`, {
      count: numberOfMinutesAgo,
    })
  }

  return i18next.t('commons:more_than_one', {
    unit: i18next.t(`commons:time.minute${format === 'short' ? '_short' : ''}`),
  })
}

const getWeeksAgo = (date: Date): number => {
  const todayDate = new Date()

  let diff = (todayDate.getTime() - date.getTime()) / 1000
  diff /= 60 * 60 * 24 * 7
  return Math.abs(Math.round(diff))
}

const getDaysAgo = (date: Date): number => {
  const todayDate = new Date()

  let diff = (todayDate.getTime() - date.getTime()) / 1000
  diff /= 60 * 60 * 24
  return Math.abs(Math.round(diff))
}

const getHoursAgo = (date: Date): number => {
  const todayDate = new Date()

  let diff = (todayDate.getTime() - date.getTime()) / 1000
  diff /= 60 * 60
  return Math.abs(Math.round(diff))
}

const getMinutesAgo = (date: Date): number => {
  const todayDate = new Date()

  let diff = (todayDate.getTime() - date.getTime()) / 1000
  diff /= 60
  return Math.abs(Math.round(diff))
}
