import { useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Avatar, IconButton, LoadingDots, Paragraph, Stack, TrashIcon } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import type { MouseEvent } from 'react'

import { ToolTip } from '../../../ui-atoms/tooltip'
import { mutations } from '../../../data/applications-by-board-list'
import { formatDateYYYYMMDD } from '../../../util-functions/date.util'
import { MatchingScore } from '../../../ui-atoms/matching-score'
import { ApplicantCardNote } from '../../applications/board/applicant-card-note'
import { ApplicantCardVerifications } from '../../applications/board/applicant-card-verifications'
import type { NewApplicationsQuery_homeApplications_edges_node } from '../../../data/graphql/types/__generated__/NewApplicationsQuery'

const Card = styled(Link)(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  paddingRight: theme.spacing['2x'],
}))

const LeftSection = Stack
const DetailsWrapper = Stack
const RightSection = Stack
const ActionsContainer = Stack

const ApplicationDate = styled(Paragraph)({
  whiteSpace: 'nowrap',
})

const StyledAvatar = styled(Avatar)({
  flexShrink: 0,
})

const NEW_APPLICATIONS_BOARD_SETTINGS = {
  showEmployerCertificate: true,
  showIdentification: true,
  showIncome: true,
  showMatchingScore: true,
  showStudyCertificate: true,
  showQueueDays: true,
  showLastMessage: true,
  showUc: true,
}

type NewApplicantCardProps = {
  data: NewApplicationsQuery_homeApplications_edges_node
}

export function NewApplicantCard({ data }: NewApplicantCardProps) {
  const { t } = useTranslation(['applications', 'applicant', 'commons'])
  const [removeHomeApplicationFromBoardList, { error, loading: isRemoveLoading }] = useMutation(
    mutations.REMOVE_HOME_APPLICATIONS_FROM_BOARD_LIST,
    {
      refetchQueries: ['NewApplicationsQuery'],
    },
  )

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  const handleDecline = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault()
    removeHomeApplicationFromBoardList({ variables: { homeApplicationIds: [id] } })
  }

  if (isRemoveLoading) {
    return (
      <Stack direction="row" justifyContent="space-between">
        <LoadingDots />
      </Stack>
    )
  }

  const { id, tenant, tenantFamilyName, home, createdAt: applicationCreatedAt } = data
  const { firstName, uid, userNotes, profilePicture } = tenant
  const { id: homeId, location, apartmentNumber } = home

  const avatarSrc = profilePicture?.url
  const applicantPath = `/applications/requests/?listing=${homeId}&applicant=${id}}`

  const formattedTenantName = `${firstName} ${tenantFamilyName}`
  const formattedHomeAddress = apartmentNumber
    ? `${location?.route} ${location?.streetNumber}, ${apartmentNumber}`
    : `${location?.route} ${location?.streetNumber}`
  const formattedApplicationDate = applicationCreatedAt
    ? formatDateYYYYMMDD(new Date(applicationCreatedAt))
    : ''

  return (
    <Card to={applicantPath}>
      <Stack direction="row" justifyContent="space-between">
        <LeftSection direction={'row'} gap={'4x'}>
          <StyledAvatar src={avatarSrc} />
          <DetailsWrapper gap={'2x'}>
            <Stack>
              <Paragraph>{formattedTenantName}</Paragraph>
              <Paragraph size="sm">{formattedHomeAddress}</Paragraph>
            </Stack>
            <MatchingScore tenantUid={uid} matchingHomeId={homeId!} />
            <ApplicantCardVerifications
              data={data}
              applicationBoardCardInfo={NEW_APPLICATIONS_BOARD_SETTINGS}
            />
            <ApplicantCardNote userNotes={userNotes} />
          </DetailsWrapper>
        </LeftSection>
        <RightSection justifyContent="space-between" alignItems="flex-end">
          <ApplicationDate size="xs" color="subtle" textAlign="right">
            {formattedApplicationDate}
          </ApplicationDate>
          <ActionsContainer direction="row" gap="3x">
            <ToolTip content={t('applicant:applicant_actions.remove')}>
              <IconButton
                label={t('applicant:applicant_actions.remove')}
                icon={TrashIcon}
                size="xs"
                onClick={handleDecline}
              />
            </ToolTip>
          </ActionsContainer>
        </RightSection>
      </Stack>
    </Card>
  )
}
