import styled from 'styled-components/macro'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Stack } from '@qasa/qds-ui'

import { palette } from '../../../styling'
import { getHalfYearAgoDate, formatDateYYYYMMDD } from '../../../util-functions/date.util'
import { queries as applicantDocumentQueries } from '../../../data/applicant-document'
import type { DocumentIncomeVerification } from '../../../data/graphql/types/__generated__/DocumentIncomeVerification'
import { formatMoney } from '../../../util-functions/general.util'
import { InformationBox } from '../../../ui-atoms/information-box'
import { useApplicantContext } from '../../../context/applicant-context'
import { CreditReportFetchStatusEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import { Typography } from '../../../ui-shared/_core/typography'
import { LoadingDots } from '../../../ui-shared/loading-dots'

import { formatIncomeData } from './format-income-data'
import { DocumentDataCard } from './document-data-card'
import { formatStudentInfo } from './format-student-info'
import { formatEmploymentInfo } from './format-employment-info'
import { formatCreditReportData } from './format-credit-report-data'

const MainWrapper = styled(Stack)({
  padding: '32px 0',
})

const FileIcon = styled(FontAwesomeIcon).attrs({ icon: ['fal', 'file-alt'], color: 'black' })({
  marginRight: 8,
})

const DocumentLink = styled.a({
  lineHeight: 2,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const DocumentLinkLabel = styled(Typography).attrs({ as: 'span', variant: 'bodySmall' })({
  color: palette.blue500,
})

export function Document() {
  const { t } = useTranslation(['applications', 'commons'])
  const { applicantData } = useApplicantContext()
  const userId = applicantData!.tenant.uid
  const { GET_DOCUMENT_INCOME_VERIFICATIONS } = applicantDocumentQueries
  const {
    loading: isLoadingIncomeDocument,
    error: errorIncomeDocument,
    data: incomeDocumentData,
  } = useQuery<DocumentIncomeVerification>(GET_DOCUMENT_INCOME_VERIFICATIONS, {
    variables: {
      incomeVerificationUid: userId,
      userUid: userId,
    },
  })

  if (isLoadingIncomeDocument) {
    return <LoadingDots />
  }
  if (errorIncomeDocument) {
    // eslint-disable-next-line no-console
    console.error(errorIncomeDocument.message)
  }

  const userStudentVerification = incomeDocumentData?.user?.studentVerification
  const studentInformation = formatStudentInfo(incomeDocumentData?.user?.studentVerification)

  const employmentVerification = incomeDocumentData?.user?.employmentVerification
  const employmentInformation = formatEmploymentInfo(incomeDocumentData?.user?.employmentVerification)

  const hasIncomeVerification = Boolean(incomeDocumentData?.incomeVerification)
  const incomeVerifiedAt = incomeDocumentData?.incomeVerification?.verifiedAt
  const incomeItems = formatIncomeData(incomeDocumentData?.incomeVerification)
  const totalIncomeMean = incomeDocumentData?.incomeVerification?.totalIncomeMean
    ? formatMoney(incomeDocumentData?.incomeVerification?.totalIncomeMean)
    : t('placeholders.missing_information')

  const hasCreditReport = Boolean(
    incomeDocumentData?.user?.creditReport &&
      incomeDocumentData?.user?.creditReport?.fetchStatus === CreditReportFetchStatusEnum.downloaded,
  )
  const creditReportItems = formatCreditReportData(incomeDocumentData?.user?.creditReport)
  const { createdAt: creditReportCreatedAt } = incomeDocumentData?.user?.creditReport || {}

  const hasNoDocuments =
    !hasIncomeVerification && !hasCreditReport && !userStudentVerification && !employmentVerification

  // Moving this to a variable so that it's not hardcoded in the translation file
  // TODO: fix when we have multiple platforms
  const platform = 'Blocket Bostad'

  /**
   * - Credit verification = Kreditupplysning
      - Record of non payment = betalningsanmärkning
      - Debt resctructuring = skulder hos Kronofogden
      - Credit verified on = Kreditkontroll genomförd den
   */
  return (
    <MainWrapper gap={'12x'}>
      {hasNoDocuments && <Typography variant="bodyLarge">{t('documents.no_documents')}</Typography>}
      {hasCreditReport && (
        <Stack gap={'4x'}>
          <DocumentDataCard
            infoItems={creditReportItems ?? []}
            verifiedAt={creditReportCreatedAt ?? ''}
            label={t('documents.credit_report')}
          />
          <InformationBox>{t('documents.credit_report_desc')}</InformationBox>
        </Stack>
      )}
      {hasIncomeVerification && (
        <Stack gap={'4x'}>
          <DocumentDataCard
            infoItems={incomeItems ?? []}
            verifiedAt={incomeVerifiedAt ?? ''}
            label={t('documents.income')}
            mainInfoItem={<Typography>{totalIncomeMean}</Typography>}
          />
          {incomeVerifiedAt && (
            <InformationBox>
              {t('documents.income_desc', {
                verifyDateStart: getHalfYearAgoDate(new Date(incomeVerifiedAt)),
                verifyDateEnd: formatDateYYYYMMDD(new Date(incomeVerifiedAt)),
              })}
            </InformationBox>
          )}
        </Stack>
      )}
      {employmentVerification && (
        <Stack gap={'4x'}>
          <DocumentDataCard
            infoItems={employmentInformation ?? []}
            label={t('documents.employer_certificate')}
            mainInfoItem={
              employmentVerification.upload ? (
                <DocumentLink href={employmentVerification.upload.url} target="_blank">
                  <FileIcon />
                  <DocumentLinkLabel>{employmentVerification.upload.title}</DocumentLinkLabel>
                </DocumentLink>
              ) : undefined
            }
          />
          <InformationBox>{t('documents.employer_certificate_desc', { platform })}</InformationBox>
        </Stack>
      )}
      {userStudentVerification && (
        <Stack gap={'4x'}>
          <DocumentDataCard
            infoItems={studentInformation ?? []}
            label={t('documents.student_info')}
            mainInfoItem={
              userStudentVerification?.upload ? (
                <DocumentLink href={userStudentVerification.upload.url} target="_blank">
                  <FileIcon />
                  <DocumentLinkLabel>{userStudentVerification.upload.title}</DocumentLinkLabel>
                </DocumentLink>
              ) : undefined
            }
          />
          <InformationBox>{t('documents.student_info_desc', { platform })} </InformationBox>
        </Stack>
      )}
    </MainWrapper>
  )
}
