import { gql } from '@apollo/client'
const GET_DOCUMENT_INCOME_VERIFICATIONS = gql`
  query DocumentIncomeVerification($incomeVerificationUid: ID!, $userUid: ID!) {
    incomeVerification(uid: $incomeVerificationUid) {
      id
      otherIncomesMean
      totalIncomeMean
      salaryMean
      verifiedAt
      updatedAt
      pensionMean
      createdAt
      cashDepositsMean
      benefitsMean
    }
    user(uid: $userUid) {
      employmentVerification {
        employerName
        id
        role
        updatedAt
        upload {
          id
          url
          type
          title
        }
      }
      certificate {
        status
        updatedAt
        expiresAt
        evaluatedAt
        createdAt
        certifiedAt
        numberOfComplaints
        sumPrivatePublicDebt
      }
      creditReport {
        createdAt
        currentAddress {
          city
          postalCode
          street
        }
        fetchStatus
        id
        moveInfo {
          count
          years
        }
        numberPaymentRemarks
        totalDebt
        yearlyIncomeStatements {
          amount
          year
        }
        guardianship
        seizureAttempt
        debtSanitization
      }
      studentVerification {
        id
        educationType
        school
        program
        startDate
        expectedFinishDate
        updatedAt
        upload {
          id
          title
          type
          url
        }
      }
    }
  }
`

export const queries = {
  GET_DOCUMENT_INCOME_VERIFICATIONS,
}
