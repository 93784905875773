import { useState } from 'react'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { CreditReportFetchStatusEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import { formatNumber, formatMoney } from '../../../util-functions/general.util'
import { VerticalSpacer } from '../../../ui-atoms/help-components/spacer'
import { getPixelAmount } from '../../../styling/pixel-amount'
import { HorizontalDivider, Spacer } from '../../../ui-atoms/help-components'
import { fontSize, palette, placeholder } from '../../../styling'
import { queries as applicantOverviewQueries } from '../../../data/applicant-overview'
import type { OverviewIncome } from '../../../data/graphql/types/__generated__/OverviewIncome'
import { getDaysFromNow } from '../../../util-functions/date.util'
import { Chat } from '../../applicant/conversation/chat'
import { MediumHeader } from '../../../ui-atoms/header-text'
import type { StudentVerification } from '../../../data/graphql/types/__generated__/StudentVerification'
import { InformationBox } from '../../../ui-atoms/information-box'
import { useApplicantContext, ApplicantTab } from '../../../context/applicant-context'
import { MatchingScore } from '../../../ui-atoms/matching-score'
import { ListingApplicationStats } from '../listing-application-stats'
import { LoadingDots } from '../../../ui-shared/loading-dots'
import { getMoveInPreferenceMatch } from '../board/applicant-card-verifications.utils'

import { OverviewAlsoAppliedTo } from './overview-also-applied-to'
import { OverviewComments } from './overview-comments'
import { OverviewApplicantProfile } from './overview-applicant-profile'
import { OverviewVerifications } from './overview-verifications'

const Wrapper = 'div'
const Text = styled.div({
  color: palette.textPrimary,
  fontWeight: 400,
  fontSize: fontSize.textSm,
})

const HeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const LinkStyled = styled.p({
  color: palette.textSecondary,
  fontWeight: 400,
  fontSize: fontSize.textSm,
  textDecoration: 'underline',
  cursor: 'pointer',
  alignSelf: 'center',
  '&:hover': {
    color: palette.secondary,
  },
  '&.active': {
    color: palette.secondary,
  },
  display: 'inline',
})

const IdNumberWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

export const EndSpace = styled.div({
  height: getPixelAmount(16),
})

export function ApplicantOverview() {
  const { t } = useTranslation(['applications', 'commons'])
  const { applicantData } = useApplicantContext()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const listing = searchParams.get('listing') || ''
  const { openApplicant } = useApplicantContext()
  const [isShowMore, setIsShowMore] = useState(false)
  const tenantName = applicantData!.tenant.firstName
  const isTenantIdVerified = Boolean(applicantData!.tenant.currentIdentification?.identityConfirmed)
  const homeApplicationsToMyHomes = applicantData!.tenant.homeApplicationsToMyHomes
  const createdAt = applicantData!.tenant.createdAt || placeholder.zero
  const tenantEmail = applicantData!.tenantEmail || placeholder.emptyString
  const tenanatPhoneNumber = applicantData!.tenantPhoneNumber || placeholder.emptyString
  const tenantIdNumber = applicantData!.tenantIdNumber
  const hasCreditReport = Boolean(
    applicantData!.tenant.creditReport &&
      applicantData!.tenant.creditReport?.fetchStatus === CreditReportFetchStatusEnum.downloaded,
  )
  const paymentRemarks = applicantData!.tenant.creditReport?.numberPaymentRemarks
  const incomeVerified = applicantData!.tenant.incomeVerifiedAt
  const employmentVerified = applicantData!.tenant.employmentVerification
  const userNotes = applicantData!.tenant.userNotes
  const homeApplicationId = applicantData!.id
  const conversationData = {
    homeApplicationsToMyHomes,
    userNotes,
    homeApplicationId,
  }
  const userId = applicantData!.tenant.uid

  const homeStartOptimal = applicantData!.home.duration.startOptimal
  const tenantStartOptimal = applicantData!.startOptimal
  const formattedHomeStartOptimal = homeStartOptimal ? format(new Date(homeStartOptimal), 'PP') : '/'
  const formattedTenantStartOptimal = tenantStartOptimal ? format(new Date(tenantStartOptimal), 'PP') : '/'
  const moveInPreferenceMatch = getMoveInPreferenceMatch({
    duration: applicantData!.home.duration,
    startOptimal: tenantStartOptimal,
  })
  const formattedMoveInPreferenceMatch = {
    ...moveInPreferenceMatch,
    title: t(`board_settings.${moveInPreferenceMatch.title}`, {
      landlordDate: formattedHomeStartOptimal,
      tenantDate: formattedTenantStartOptimal,
      defaultValue: '',
    }),
  }

  const { GET_OVERVIEW_INCOME, GET_STUDENT_VERIFICATIONS } = applicantOverviewQueries
  const {
    loading: isLoadingIncome,
    error: errorIncome,
    data: incomeData,
  } = useQuery<OverviewIncome>(GET_OVERVIEW_INCOME, {
    variables: {
      incomeVerificationUid: userId,
    },
  })

  const {
    loading: isLoadingStudentVerifications,
    error: errorStudentVerifications,
    data: studentVerificationData,
  } = useQuery<StudentVerification>(GET_STUDENT_VERIFICATIONS, {
    variables: {
      userUid: userId,
    },
  })
  const userStudentVerification = studentVerificationData?.user?.studentVerification

  if (isLoadingIncome || isLoadingStudentVerifications) return <LoadingDots />
  if (errorIncome || errorStudentVerifications) {
    // eslint-disable-next-line no-console
    console.error(errorIncome?.message)
    // eslint-disable-next-line no-console
    console.error(errorStudentVerifications?.message)
  }

  const totalIncomeMean = incomeData?.incomeVerification?.totalIncomeMean
    ? formatMoney(incomeData?.incomeVerification?.totalIncomeMean)
    : t('commons:placeholders.missing_information')

  const numberId = tenantIdNumber
    ? isShowMore
      ? tenantIdNumber.substring(0, 8) + '-' + tenantIdNumber.substring(8, 12)
      : tenantIdNumber?.substring(0, 8) + '-XXXX'
    : placeholder.emptyString

  return (
    <Wrapper>
      <Spacer factor={4} />
      <MediumHeader>{t('overview.title')}</MediumHeader>
      <Spacer factor={4} />
      <Text> {tenantEmail}</Text>
      <Text>{tenanatPhoneNumber}</Text>
      <IdNumberWrapper>
        <Text>{numberId}</Text>
        <VerticalSpacer factor={1} />
        {tenantIdNumber && !isShowMore && (
          <LinkStyled onClick={() => setIsShowMore(!isShowMore)}>{t('overview.show_full_number')}</LinkStyled>
        )}
      </IdNumberWrapper>
      <Spacer factor={3} />
      <OverviewVerifications
        isTenantIdVerified={isTenantIdVerified}
        incomeVerified={incomeVerified}
        hasCreditReport={hasCreditReport}
        paymentRemarks={paymentRemarks}
        totalIncomeMean={totalIncomeMean}
        moveInPreferenceMatch={formattedMoveInPreferenceMatch}
        userStudentVerification={userStudentVerification}
        employmentVerified={employmentVerified}
      />
      <Spacer factor={3} />
      <MatchingScore tenantUid={userId} matchingHomeId={listing} isLarge />
      <ListingApplicationStats
        header={t('overview.days_in_queue_label')}
        value={formatNumber(getDaysFromNow(new Date(createdAt)) || 0)}
        isValueBold
        isLarge
      />
      <Spacer factor={4} />
      <HorizontalDivider />
      <Spacer factor={6} />
      <OverviewApplicantProfile data={applicantData!} />
      <Spacer factor={4} />
      <HorizontalDivider />
      <>
        <Spacer factor={8} />
        <HeaderContainer>
          <MediumHeader>{t('applicant_tabs.messages')}</MediumHeader>
          <LinkStyled onClick={() => openApplicant(ApplicantTab.Conversation)}>
            {t('overview.to_messages')}
          </LinkStyled>
        </HeaderContainer>
        <Spacer factor={4} />
        <Chat isPreview />
        <Spacer factor={6} />
        <HorizontalDivider />
      </>

      <Spacer factor={8} />
      <OverviewAlsoAppliedTo />
      <Spacer factor={4} />
      <InformationBox>{t('overview.also_applied_to.description', { name: tenantName })}</InformationBox>
      <Spacer factor={4} />
      <HorizontalDivider />
      <Spacer factor={8} />
      <HeaderContainer>
        <MediumHeader>{t('applicant_tabs.internal_comments')}</MediumHeader>
        <LinkStyled onClick={() => openApplicant(ApplicantTab.Comments)}>
          {t('overview.to_comments')}
        </LinkStyled>
      </HeaderContainer>
      <OverviewComments data={conversationData} isWritingAvailable={false} />
      <Spacer factor={4} />
      <InformationBox>
        <div>
          {t('overview.comments.description')}
          <LinkStyled onClick={() => openApplicant(ApplicantTab.Comments)}>
            {t('applicant_tabs.internal_comments')}
          </LinkStyled>
        </div>
      </InformationBox>
    </Wrapper>
  )
}
