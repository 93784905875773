import { useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Button, Paragraph, SettingsIcon, Stack } from '@qasa/qds-ui'
import { useState } from 'react'
import { Dialog } from '@qasa/app/src/components/dialog'

import { InformationBox } from '../../../ui-atoms/information-box'
import { mutations } from '../../../data/application-board-lists'
import type { UpsertApplicationBoardCardInfoInput } from '../../../data/graphql/types/__generated__/globalTypes'
import type { ApplicationBoardLists_home_applicationBoardCardInfo } from '../../../data/graphql/types/__generated__/ApplicationBoardLists'
import { useUserContext } from '../../../context/user-context'

import { BoardSetting } from './board-setting'

const RowWrapper = Stack

const Icon = styled(FontAwesomeIcon)({
  alignSelf: 'center',
})

type BoardSettingsProps = {
  applicationBoardCardInfo: ApplicationBoardLists_home_applicationBoardCardInfo | null | undefined
}

export enum BoardSettingsEnum {
  ID = 'ID',
  CREDIT_REPORT = 'CREDIT_REPORT',
  PAYMENT_REMARKS = 'PAYMENT_REMARKS',
  INCOME = 'INCOME',
  STUDENT = 'STUDENT',
  EMPLOYMENT = 'EMPLOYMENT',
  MOVE_IN = 'MOVE_IN',
  MATCH = 'MATCH',
  QUEUE = 'QUEUE',
  LASTMESSAGE = 'LASTMESSAGE',
}

export function BoardSettings({ applicationBoardCardInfo }: BoardSettingsProps) {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const homeId = searchParams.get('listing')
  const { authBody } = useUserContext()

  const { t } = useTranslation('applications')

  const [upsertApplicationBoardCardInfo] = useMutation(mutations.UPSERT_APPLICATION_BOARD_CARD_INFO, {
    refetchQueries: ['ApplicationBoardLists'],
  })

  const shouldShowID = applicationBoardCardInfo ? applicationBoardCardInfo.showIdentification : false
  const shouldShowCreditReport = applicationBoardCardInfo ? applicationBoardCardInfo.showUc : false
  const shouldShowIncome = applicationBoardCardInfo ? applicationBoardCardInfo.showIncome : false
  const shouldShowStudent = applicationBoardCardInfo ? applicationBoardCardInfo.showStudyCertificate : false
  const shouldShowEmployment = applicationBoardCardInfo
    ? applicationBoardCardInfo.showEmployerCertificate
    : false
  const shouldShowMatch = applicationBoardCardInfo ? applicationBoardCardInfo.showMatchingScore : false
  const shouldShowQueue = applicationBoardCardInfo ? applicationBoardCardInfo.showQueueDays : false
  const shouldShowLastMessage = applicationBoardCardInfo ? applicationBoardCardInfo.showLastMessage : false
  const shouldShowMoveInPreference = authBody?.isProAgent

  const handleSelectSetting = (type: BoardSettingsEnum) => {
    const inputParams: UpsertApplicationBoardCardInfoInput = {
      showIdentification: type === BoardSettingsEnum.ID ? !shouldShowID : shouldShowID,
      showUc: type === BoardSettingsEnum.CREDIT_REPORT ? !shouldShowCreditReport : shouldShowCreditReport,
      showIncome: type === BoardSettingsEnum.INCOME ? !shouldShowIncome : shouldShowIncome,
      showStudyCertificate: type === BoardSettingsEnum.STUDENT ? !shouldShowStudent : shouldShowStudent,
      showEmployerCertificate:
        type === BoardSettingsEnum.EMPLOYMENT ? !shouldShowEmployment : shouldShowEmployment,
      showMatchingScore: type === BoardSettingsEnum.MATCH ? !shouldShowMatch : shouldShowMatch,
      showQueueDays: type === BoardSettingsEnum.QUEUE ? !shouldShowQueue : shouldShowQueue,
      showLastMessage:
        type === BoardSettingsEnum.LASTMESSAGE ? !shouldShowLastMessage : shouldShowLastMessage,
    }

    upsertApplicationBoardCardInfo({
      variables: {
        homeId,
        params: inputParams,
      },
    })
  }
  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(isOpen) => setIsOpen(isOpen)}
      size="md"
      trigger={
        <Button variant="tertiary" size="sm" onClick={() => setIsOpen(true)}>
          <Stack gap="2x" direction="row" alignItems="center">
            <SettingsIcon size={16} />
            {t('board_actions.customise_card_info')}
          </Stack>
        </Button>
      }
    >
      <Dialog.Body>
        <Stack gap="8x">
          <Dialog.Header>
            <Dialog.Title>{t('board_actions.customise_card_info')}</Dialog.Title>
          </Dialog.Header>
          <BoardSetting
            value={t('board_settings.id')}
            okLabel={t('board_settings.verified')}
            undefLabel={t('board_settings.unverified')}
            isChecked={shouldShowID}
            type={BoardSettingsEnum.ID}
            handleSelectSetting={handleSelectSetting}
          />
          <BoardSetting
            value={t('board_settings.credit_report')}
            okLabel={t('board_settings.uploaded')}
            undefLabel={t('board_settings.not_uploaded')}
            isChecked={shouldShowCreditReport}
            type={BoardSettingsEnum.CREDIT_REPORT}
            handleSelectSetting={handleSelectSetting}
          />
          <BoardSetting
            value={t('board_settings.payment_remarks')}
            okLabel={t('board_settings.no_payment_remarks')}
            undefLabel={t('board_settings.no_credit_report')}
            badLabel={t('board_settings.has_payment_remarks')}
            isChecked={shouldShowCreditReport}
            isDisabled
            type={BoardSettingsEnum.PAYMENT_REMARKS}
            handleSelectSetting={handleSelectSetting}
          />
          <BoardSetting
            value={t('board_settings.income')}
            okLabel={t('board_settings.stated')} //ska kolla om det är över hyreskraven
            undefLabel={t('board_settings.not_stated')}
            isChecked={shouldShowIncome}
            type={BoardSettingsEnum.INCOME}
            handleSelectSetting={handleSelectSetting}
          />
          <BoardSetting
            value={t('board_settings.employer_certificate')}
            okLabel={t('board_settings.uploaded')}
            undefLabel={t('board_settings.not_uploaded')}
            isChecked={shouldShowEmployment}
            type={BoardSettingsEnum.EMPLOYMENT}
            handleSelectSetting={handleSelectSetting}
          />
          <BoardSetting
            value={t('board_settings.study_certificate')}
            okLabel={t('board_settings.uploaded')}
            undefLabel={t('board_settings.not_uploaded')}
            isChecked={shouldShowStudent}
            type={BoardSettingsEnum.STUDENT}
            handleSelectSetting={handleSelectSetting}
          />
          {shouldShowMoveInPreference && (
            <BoardSetting
              value={t('board_settings.move_in')}
              okLabel={t('board_settings.move_in_ok_label')}
              undefLabel={t('board_settings.move_in_undef_label')}
              badLabel={t('board_settings.move_in_bad_label')}
              isChecked={shouldShowMoveInPreference}
              isDisabled
              type={BoardSettingsEnum.MOVE_IN}
              handleSelectSetting={handleSelectSetting}
            />
          )}
          <Stack gap="2x">
            <BoardSetting
              value={t('board_settings.matching')}
              handleSelectSetting={handleSelectSetting}
              isChecked={shouldShowMatch}
              type={BoardSettingsEnum.MATCH}
            />
            <RowWrapper direction="row" gap="2x">
              <Paragraph>{`% ${t('board_settings.matching_explanation')}`}</Paragraph>
            </RowWrapper>
            <RowWrapper direction="row" gap="2x">
              <Icon icon={['fal', 'minus-circle']} size={'sm'} />
              <Paragraph>{t('board_settings.matching_not_enough_info')}</Paragraph>
            </RowWrapper>
          </Stack>
          <BoardSetting
            value={t('board_settings.queue_time')}
            handleSelectSetting={handleSelectSetting}
            isChecked={shouldShowQueue}
            type={BoardSettingsEnum.QUEUE}
          />
          <BoardSetting
            value={t('board_settings.latest_message')}
            handleSelectSetting={handleSelectSetting}
            isChecked={shouldShowLastMessage}
            type={BoardSettingsEnum.LASTMESSAGE}
          />
          <InformationBox>{t('board_settings.tags_explanation')}</InformationBox>
        </Stack>
      </Dialog.Body>
    </Dialog>
  )
}
