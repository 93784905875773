import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { ApplicantTab, useApplicantContext } from '../../../context/applicant-context'
import type { HomeApplicationOverview_homeApplication_tenant_employmentVerification } from '../../../data/graphql/types/__generated__/HomeApplicationOverview'
import type { StudentVerification_user_studentVerification } from '../../../data/graphql/types/__generated__/StudentVerification'
import { fontSize, palette } from '../../../styling'
import { VerticalSpacer } from '../../../ui-atoms/help-components/spacer'
import { VerificationBadge, Status } from '../../../ui-atoms/verification-badge'
import { Stack } from '../../../ui-shared/_core/stack'
import { useUserContext } from '../../../context/user-context'
import type { MoveInPreferenceMatch } from '../board/applicant-card-verifications.utils'

const BadgeContainer = Stack

const BadgeContentWrapper = styled.div({
  display: 'flex',
})

const LinkStyled = styled.p({
  color: palette.textSecondary,
  fontWeight: 400,
  fontSize: fontSize.textSm,
  textDecoration: 'underline',
  cursor: 'pointer',
  alignSelf: 'center',
  '&:hover': {
    color: palette.secondary,
  },
  '&.active': {
    color: palette.secondary,
  },
  display: 'inline',
})

type OverviewVerificationsProps = {
  isTenantIdVerified: boolean
  incomeVerified: string | null
  hasCreditReport: boolean
  moveInPreferenceMatch: MoveInPreferenceMatch
  paymentRemarks?: number | null
  totalIncomeMean: string
  userStudentVerification: StudentVerification_user_studentVerification | null | undefined
  employmentVerified: HomeApplicationOverview_homeApplication_tenant_employmentVerification | null
}
export function OverviewVerifications(props: OverviewVerificationsProps) {
  const { openApplicant } = useApplicantContext()
  const { t } = useTranslation('applications', { keyPrefix: 'board_settings' })
  const { authBody } = useUserContext()

  const isProAgent = authBody?.isProAgent

  return (
    <BadgeContainer gap={1}>
      <VerificationBadge
        details={props.isTenantIdVerified ? t('verified_bankid') : t('unverified')}
        status={props.isTenantIdVerified ? Status.Positive : Status.Default}
        label={t('id')}
      />
      <BadgeContentWrapper>
        <VerificationBadge
          details={props.hasCreditReport ? t('uploaded') : t('not_uploaded')}
          status={props.hasCreditReport ? Status.Positive : Status.Default}
          label={t('credit_report')}
        />
        {props.hasCreditReport && (
          <>
            <VerticalSpacer factor={1} />
            <LinkStyled onClick={() => openApplicant(ApplicantTab.Document)}>{t('view_details')}</LinkStyled>
          </>
        )}
      </BadgeContentWrapper>
      <BadgeContentWrapper>
        <VerificationBadge
          details={
            props.hasCreditReport
              ? props.paymentRemarks
                ? t('number_payment_remarks', { count: props.paymentRemarks })
                : t('no_payment_remarks')
              : t('no_credit_report')
          }
          status={
            props.hasCreditReport
              ? props.paymentRemarks
                ? Status.Negative
                : Status.Positive
              : Status.Default
          }
          label={t('payment_remarks')}
        />
      </BadgeContentWrapper>
      <BadgeContentWrapper>
        <VerificationBadge
          details={props.incomeVerified ? `${props.totalIncomeMean}` : t('unverified')}
          status={props.incomeVerified ? Status.Positive : Status.Default}
          label={t('income')}
        />
        {props.incomeVerified && (
          <>
            <VerticalSpacer factor={1} />
            <LinkStyled onClick={() => openApplicant(ApplicantTab.Document)}>{t('view_details')}</LinkStyled>
          </>
        )}
      </BadgeContentWrapper>
      <VerificationBadge
        label={t('study_certificate')}
        details={props.userStudentVerification ? t('uploaded') : t('not_uploaded')}
        status={props.userStudentVerification ? Status.Positive : Status.Default}
      />
      <VerificationBadge
        details={props.employmentVerified ? t('uploaded') : t('not_uploaded')}
        status={props.employmentVerified ? Status.Positive : Status.Default}
        label={t('employer_certificate')}
      />
      {isProAgent && (
        <VerificationBadge
          details={props.moveInPreferenceMatch.title}
          status={props.moveInPreferenceMatch.status}
          label={t('move_in')}
        />
      )}
    </BadgeContainer>
  )
}
