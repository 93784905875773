import { gql } from '@apollo/client'

const GET_APPLICATION_BY_BOARD_LIST = gql`
  query HomeApplicationsByBoardList(
    $homeApplicationsByBoardListId: ID!
    $fulfillsRentalRequirements: Boolean
    $searchString: String = ""
    $orderBy: HomeApplicationOrderEnum
    $maxIncome: Int
    $minIncome: Int
    $tenantVerifications: [TenantVerificationEnum!]
    $first: Int
    $after: String
  ) {
    homeApplicationsByBoardList(
      id: $homeApplicationsByBoardListId
      fulfillsRentalRequirements: $fulfillsRentalRequirements
      searchString: $searchString
      orderBy: $orderBy
      maxIncome: $maxIncome
      minIncome: $minIncome
      tenantVerifications: $tenantVerifications
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          tenantFamilyName
          tenant {
            uid
            firstName
            seenAt
            profilePicture {
              url
              title
            }
            homeApplicationsToMyHomes {
              matchInterest
              message
              updatedAt
            }
            certificate {
              status
              updatedAt
            }
            creditReport {
              id
              fetchStatus
              numberPaymentRemarks
            }
            bio {
              study
            }
            currentIdentification {
              identityConfirmed
              identityConfirmedAt
            }
            createdAt
            incomeVerifiedAt
            employmentVerification {
              id
              updatedAt
            }
            studentVerification {
              id
              updatedAt
            }
            userNotes {
              note
            }
          }
          home {
            id
            apartmentNumber
            numberOfHomes
            location {
              route
              streetNumber
              locality
            }
            uploads {
              url
              title
            }
            duration {
              startAsap
              startOptimal
            }
          }
          conversation {
            id
            messages(last: 1) {
              nodes {
                createdAt
                message
                user {
                  uid
                }
                messageType
              }
            }
            read
          }
          updatedAt
          status
          createdAt
          starMarkedByLandlord
          startOptimal
        }
      }
    }
  }
`

const ADD_HOME_APPLICATIONS_TO_BOARD_LIST = gql`
  mutation AddHomeApplicationsToBoardList($applicationBoardListId: ID!, $homeApplicationIds: [ID!]!) {
    addHomeApplicationsToBoardList(
      applicationBoardListId: $applicationBoardListId
      homeApplicationIds: $homeApplicationIds
    ) {
      errors {
        codes
      }
    }
  }
`

const REMOVE_HOME_APPLICATIONS_FROM_BOARD_LIST = gql`
  mutation RemoveHomeApplicationFromBoardList($homeApplicationIds: [ID!]!) {
    removeHomeApplicationsFromBoardList(homeApplicationIds: $homeApplicationIds) {
      errors {
        codes
      }
    }
  }
`

const SEND_MASS_MESSAGE = gql`
  mutation SendMassMessage($conversationIds: [ID!]!, $message: String!) {
    sendMassMessage(conversationIds: $conversationIds, message: $message) {
      errors {
        codes
      }
    }
  }
`

export const queries = {
  GET_APPLICATION_BY_BOARD_LIST,
}

export const mutations = {
  ADD_HOME_APPLICATIONS_TO_BOARD_LIST,
  REMOVE_HOME_APPLICATIONS_FROM_BOARD_LIST,
  SEND_MASS_MESSAGE,
}
