import styled from 'styled-components/macro'
import { Heading, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { TableTopSection } from '../ui-atoms/top-section'
import { IframeInformation } from '../ui-feature-modules/settings/iframe-information'
import { WhiteBoxWrapper } from '../ui-shared/wrapper'

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
})

export function Settings() {
  const { t } = useTranslation('settings')

  return (
    <Wrapper>
      <Stack gap="8x">
        <TableTopSection>
          <Heading as="h1" size="md">
            {t('title')}
          </Heading>
        </TableTopSection>
        <WhiteBoxWrapper>
          <Stack gap="6x">
            <IframeInformation />
          </Stack>
        </WhiteBoxWrapper>
      </Stack>
    </Wrapper>
  )
}
