import i18next from 'i18next'

import type { DocumentIncomeVerification_user_employmentVerification } from '../../../data/graphql/types/__generated__/DocumentIncomeVerification'

export const formatEmploymentInfo = (
  employmentInfo?: DocumentIncomeVerification_user_employmentVerification | null,
) => {
  if (!employmentInfo) {
    return null
  }
  const { employerName, role } = employmentInfo || {}

  return [
    {
      title: i18next.t('applications:documents.employment_info.employer'),
      content: employerName ? employerName : i18next.t('commons:placeholders.missing_information'),
    },
    {
      title: i18next.t('applications:documents.employment_info.role'),
      content: role ? role : i18next.t('commons:placeholders.missing_information'),
    },
  ]
}
