import gql from 'graphql-tag'

const GET_UNREAD_MESSAGES_COUNT = gql`
  query UnreadMessagesCountQuery($homeId: [ID!]) {
    conversations(read: false, homeId: $homeId) {
      nodes {
        id
      }
    }
  }
`

const GET_LISTING = gql`
  query ListingQuery($listingId: ID!) {
    home(id: $listingId) {
      id
      status
      publishedAt
      lastBumpedAt
      roomCount
      rent
      recommendedRentNew(forceSync: true)
      type
      floor
      buildingFloors
      firsthand
      squareMeters
      studentHome
      seniorHome
      tenantCount
      apartmentNumber
      corporateHome
      contractTemplate {
        canUse
        landlordHasKeys
        rentExcludes
        rentIncludes
        smallRepairs
      }
      instantSignSetting {
        enabled
        id
      }
      property {
        maxRent
        maxRoomCount
        maxSquareMeters
        minRent
        minRoomCount
        minSquareMeters
        numberOfHomes
      }
      location {
        longitude
        latitude
        locality
        streetNumber
        route
        postalCode
      }
      uploads {
        id
        url
        type
        title
        metadata {
          primary
          order
        }
      }
      duration {
        endOptimal
        startOptimal
        startAsap
        endUfn
        possibilityOfExtension
        durationPreference {
          startLatest
        }
      }
      #building
      descriptionBuilding
      energyClass
      descriptionTransportation
      buildYear
      #apartment
      homeTemplates {
        id
        building {
          id
          buildingName
        }
      }
      description
      descriptionContract
      kitchenRenovationYear
      bathroomRenovationYear
      traits {
        type
        id
      }
      rentalRequirement {
        id
        approvedCreditCheck
        rentMultiplier
        studentVerification
        verifiedIdNumber
        verifiedIncome
      }
      minTenantCount
      maxTenantCount
      maxRent
      maxRoomCount
      maxSquareMeters
      minRent
      minRoomCount
      minSquareMeters
      numberOfHomes
      numberOfHomes
      applicationDeadline
      archivedBecauseOfApplicationDeadlinePassed
      matterportEmbedUrl
      shortcut {
        id
        homePitch
      }
      landlord {
        uid
        firstName
        profilePicture {
          url
        }
        private {
          phoneNumber
          email
          familyName
        }
      }
    }
  }
`

const GET_LISTING_STATS = gql`
  query ListingStatsQuery($listingId: ID!) {
    homeStatsQuery(id: $listingId) {
      cumulativePageViews
      cumulativeUniquePageViews
    }
  }
`

const GET_LISTING_LOCATION = gql`
  query ListingLocationQuery($listingId: ID!) {
    home(id: $listingId) {
      apartmentNumber
      location {
        longitude
        latitude
        locality
        streetNumber
        route
        postalCode
      }
    }
  }
`

const GET_BLOCK_LISTING_APARTMENTS = gql`
  query BlockListingApartmentsQuery($homeId: ID!) {
    home(id: $homeId) {
      homeTemplates {
        id
        apartmentNumber
        roomCount
        squareMeters
        pendingOfferingsCount(homeId: $homeId)
        acceptedOfferingsCount(homeId: $homeId)
        location {
          postalCode
          route
          locality
          streetNumber
        }
        uploads {
          type
          url
          metadata {
            primary
            order
          }
        }
        building {
          buildingName
          uploads {
            type
            url
            metadata {
              primary
              order
            }
          }
        }
      }
    }
  }
`
const ARCHIVE_LISTING = gql`
  mutation ArchiveHomeMutation($archiveHomeId: ID!, $archiveReason: HomeArchiveReasonEnum) {
    archiveHome(homeId: $archiveHomeId, archiveReason: $archiveReason) {
      home {
        id
      }
    }
  }
`

export const queries = {
  GET_LISTING,
  GET_LISTING_STATS,
  GET_BLOCK_LISTING_APARTMENTS,
  GET_LISTING_LOCATION,
  GET_UNREAD_MESSAGES_COUNT,
}
export const mutations = { ARCHIVE_LISTING }
