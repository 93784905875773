import { gql } from '@apollo/client'

const GET_OVERVIEW_INCOME = gql`
  query OverviewIncome($incomeVerificationUid: ID!) {
    incomeVerification(uid: $incomeVerificationUid) {
      id
      otherIncomesMean
      totalIncomeMean
      salaryMean
    }
  }
`

const GET_STUDENT_VERIFICATIONS = gql`
  query StudentVerification($userUid: ID!) {
    user(uid: $userUid) {
      studentVerification {
        id
      }
    }
  }
`

export const queries = {
  GET_OVERVIEW_INCOME,
  GET_STUDENT_VERIFICATIONS,
}
