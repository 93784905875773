import i18next from 'i18next'

import type { DocumentIncomeVerification_user_studentVerification } from '../../../data/graphql/types/__generated__/DocumentIncomeVerification'
import { formatDateMonthYear } from '../../../util-functions/date.util'
import { capitalizeWord } from '../../../util-functions/string.util'

const education_types = {
  university: 'Universitet eller högskola',
  vocational_college: 'Yrkeshögskola',
  municipal_adult_education: 'Komvux',
  other_type: 'Annan typ av utbildning',
}

export const formatStudentInfo = (
  studentInfo?: DocumentIncomeVerification_user_studentVerification | null,
) => {
  if (!studentInfo) {
    return null
  }
  const { educationType, school, startDate, expectedFinishDate, program } = studentInfo || {}

  return [
    {
      title: i18next.t('applications:documents.format_student_info.type'),
      content: educationType
        ? education_types[educationType]
        : i18next.t('commons:placeholders.missing_information'),
    },
    {
      title: i18next.t('applications:documents.format_student_info.program'),
      content: program ? program : i18next.t('commons:placeholders.missing_information'),
    },
    {
      title: i18next.t('applications:documents.format_student_info.school'),
      content: school ? school : i18next.t('commons:placeholders.missing_information'),
    },
    {
      title: i18next.t('applications:documents.format_student_info.start_date'),
      content:
        capitalizeWord(formatDateMonthYear(new Date(startDate))) ||
        i18next.t('commons:placeholders.missing_information'),
    },
    {
      title: i18next.t('applications:documents.format_student_info.end_date'),
      content:
        capitalizeWord(formatDateMonthYear(new Date(expectedFinishDate))) ||
        i18next.t('commons:placeholders.missing_information'),
    },
  ]
}
