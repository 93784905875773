import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { VerificationBadge, Status } from '../../../ui-atoms/verification-badge'
import type { ApplicationBoardLists_home_applicationBoardCardInfo } from '../../../data/graphql/types/__generated__/ApplicationBoardLists'
import { CreditReportFetchStatusEnum } from '../../../data/graphql/types/__generated__/globalTypes'
import { Stack } from '../../../ui-shared/_core/stack'
import { useUserContext } from '../../../context/user-context'
import type { NewApplicationsQuery_homeApplications_edges_node } from '../../../data/graphql/types/__generated__/NewApplicationsQuery'

import { getMoveInPreferenceMatch } from './applicant-card-verifications.utils'

const RowWrapper = styled(Stack)<{ shouldNotHaveWidth?: boolean }>(({ shouldNotHaveWidth }) => ({
  width: shouldNotHaveWidth ? 'none' : '100%',
  flexFlow: 'wrap',
}))
const BadgeWrapper = 'div'

type ApplicantCardVerficationsProps = {
  data: Pick<NewApplicationsQuery_homeApplications_edges_node, 'tenant' | 'startOptimal' | 'home'>
  applicationBoardCardInfo?: Omit<
    ApplicationBoardLists_home_applicationBoardCardInfo,
    'id' | '__typename'
  > | null
  shouldNotHaveWidth?: boolean
}

export function ApplicantCardVerifications({
  data,
  applicationBoardCardInfo,
  shouldNotHaveWidth = false,
}: ApplicantCardVerficationsProps) {
  const { t } = useTranslation('applications', {
    keyPrefix: 'board_settings',
  })
  const { authBody } = useUserContext()

  const isTenantIdVerified = data.tenant.currentIdentification?.identityConfirmed
  const hasCreditReport = Boolean(
    data.tenant.creditReport &&
      data.tenant.creditReport.fetchStatus === CreditReportFetchStatusEnum.downloaded,
  )
  const hasPaymentRemarks = Boolean(data.tenant.creditReport?.numberPaymentRemarks)
  const incomeVerified = data.tenant.incomeVerifiedAt
  const employmentVerified = data.tenant.employmentVerification
  const studentVerification = data.tenant.studentVerification

  const tenantStartOptimal = data.startOptimal
  const formattedTenantStartOptimal = tenantStartOptimal ? format(new Date(tenantStartOptimal), 'PP') : '/'
  const moveInPreferenceMatch = getMoveInPreferenceMatch({
    duration: data.home.duration,
    startOptimal: tenantStartOptimal,
  })

  const shouldShowID = applicationBoardCardInfo ? applicationBoardCardInfo.showIdentification : false

  const shouldShowIncome = applicationBoardCardInfo ? applicationBoardCardInfo.showIncome : false
  const shouldShowCreditReport = applicationBoardCardInfo ? applicationBoardCardInfo.showUc : false
  const shouldShowStudent = applicationBoardCardInfo ? applicationBoardCardInfo.showStudyCertificate : false
  const shouldShowEmployment = applicationBoardCardInfo
    ? applicationBoardCardInfo.showEmployerCertificate
    : false
  // TODO: when adding filters for payment remarks check this in the data
  const shouldShowPaymentRemarks = true
  const isProAgent = authBody?.isProAgent

  return (
    <RowWrapper shouldNotHaveWidth={shouldNotHaveWidth} direction="row" gap={1}>
      {shouldShowID && (
        <BadgeWrapper>
          <VerificationBadge status={isTenantIdVerified ? Status.Positive : Status.Default} label={t('id')} />
        </BadgeWrapper>
      )}
      {shouldShowCreditReport && (
        <BadgeWrapper>
          <VerificationBadge
            status={hasCreditReport ? Status.Positive : Status.Default}
            label={t('credit_report')}
          />
        </BadgeWrapper>
      )}
      {shouldShowPaymentRemarks && (
        <BadgeWrapper>
          <VerificationBadge
            status={
              hasCreditReport ? (hasPaymentRemarks ? Status.Negative : Status.Positive) : Status.Default
            }
            label={t('payment_remarks')}
          />
        </BadgeWrapper>
      )}
      {shouldShowIncome && (
        <BadgeWrapper>
          <VerificationBadge status={incomeVerified ? Status.Positive : Status.Default} label={t('income')} />
        </BadgeWrapper>
      )}
      {shouldShowEmployment && (
        <BadgeWrapper>
          <VerificationBadge
            status={employmentVerified ? Status.Positive : Status.Default}
            label={t('employer_certificate')}
          />
        </BadgeWrapper>
      )}
      {shouldShowStudent && (
        <BadgeWrapper>
          <VerificationBadge
            label={t('study_certificate')}
            status={studentVerification ? Status.Positive : Status.Default}
          />
        </BadgeWrapper>
      )}
      {isProAgent && (
        <BadgeWrapper>
          <VerificationBadge
            status={moveInPreferenceMatch.status}
            label={t(moveInPreferenceMatch.label, {
              tenantDate: formattedTenantStartOptimal,
              defaultValue: '',
            })}
          />
        </BadgeWrapper>
      )}
    </RowWrapper>
  )
}
