import gql from 'graphql-tag'

const GET_TOTAL_APPLICATIONS_COUNT = gql`
  query GetTotalApplicationsCountQuery(
    $homeApplicationsExcludeOfferingStatuses: [OfferingStatusEnum!]
    $homeApplicationsHomeId: [ID!]
  ) {
    homeApplications(
      status: [pending, in_contact]
      excludeOfferingStatuses: $homeApplicationsExcludeOfferingStatuses
      homeId: $homeApplicationsHomeId
    ) {
      totalCount
    }
  }
`

const GET_TOTAL_DECLINED_COUNT = gql`
  query GetTotalDeclinedCountQuery(
    $homeApplicationsExcludeOfferingStatuses: [OfferingStatusEnum!]
    $homeApplicationsHomeId: [ID!]
  ) {
    homeApplications(
      status: [declined, revoked, closed]
      excludeOfferingStatuses: $homeApplicationsExcludeOfferingStatuses
      homeId: $homeApplicationsHomeId
    ) {
      totalCount
    }
  }
`

const GET_REQUESTS = gql`
  query HomeApplicationsRequest(
    $homeApplicationsFirst: Int
    $homeApplicationsAfter: String
    $homeApplicationsStatus: [HomeApplicationStatusEnum!]
    $homeApplicationsHomeId: [ID!]
    $homeApplicationsSearchString: String = ""
    $homeApplicationsTenantVerifications: [TenantVerificationEnum!]
    $homeApplicationsOrderBy: HomeApplicationOrderEnum
    $homeApplicationsExcludeOfferingStatuses: [OfferingStatusEnum!]
    $minIncome: Int
    $maxIncome: Int
  ) {
    homeApplications(
      after: $homeApplicationsAfter
      first: $homeApplicationsFirst
      status: $homeApplicationsStatus
      homeId: $homeApplicationsHomeId
      orderBy: $homeApplicationsOrderBy
      searchString: $homeApplicationsSearchString
      tenantVerifications: $homeApplicationsTenantVerifications
      excludeOfferingStatuses: $homeApplicationsExcludeOfferingStatuses
      minIncome: $minIncome
      maxIncome: $maxIncome
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          tenantFamilyName
          tenant {
            uid
            firstName
            seenAt
            profilePicture {
              url
              title
            }
            homeApplicationsToMyHomes {
              matchInterest
              message
              updatedAt
            }
            certificate {
              status
              updatedAt
            }
            creditReport {
              id
              fetchStatus
              numberPaymentRemarks
            }
            bio {
              study
            }
            currentIdentification {
              identityConfirmed
              identityConfirmedAt
            }
            createdAt
            incomeVerifiedAt
            employmentVerification {
              id
              updatedAt
            }
            studentVerification {
              id
              updatedAt
            }
            userNotes {
              note
            }
          }
          home {
            id
            apartmentNumber
            numberOfHomes
            location {
              route
              streetNumber
              locality
            }
            uploads {
              url
              title
            }
            duration {
              startAsap
              startOptimal
            }
          }
          conversation {
            id
            messages(last: 1) {
              nodes {
                createdAt
                message
                user {
                  uid
                }
                messageType
              }
            }
            read
          }
          updatedAt
          status
          createdAt
          starMarkedByLandlord
          startOptimal
        }
      }
    }
  }
`

export const queries = {
  GET_REQUESTS,
  GET_TOTAL_APPLICATIONS_COUNT,
  GET_TOTAL_DECLINED_COUNT,
}
