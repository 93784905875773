import { useState } from 'react'
import styled from '@emotion/styled'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Heading, TextField, Stack, Link } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { ReactComponent as BlocketXQasa } from '../assets/logos/blocket-x-qasa.svg'
import { useUserContext } from '../context/user-context'
import { FormWindow } from '../ui-atoms/form-window'
import { validateEmail } from '../util-functions/validation/email'

const Wrapper = styled(Stack)({
  height: '100%',
})

const StyledBlocketXQasaLogo = styled(BlocketXQasa)(({ theme }) => ({
  height: 60,
  width: 'auto',
  marginLeft: theme.spacing['8x'],
  alignSelf: 'flex-start',
}))

export function UnauthenticatedPageWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Wrapper>
      <StyledBlocketXQasaLogo />
      <Stack style={{ flexGrow: 1 }} alignItems="center" justifyContent="center">
        {children}
      </Stack>
    </Wrapper>
  )
}

export function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { t } = useTranslation(['login', 'commons'])

  const formIsValid = () => {
    return validateEmail(email) && password !== ''
  }

  const { login, authError, clearAuthError } = useUserContext()

  return (
    <UnauthenticatedPageWrapper>
      <FormWindow>
        <Stack gap="8x">
          <Heading as="h1" size="md">
            {t('title')}
          </Heading>
          <Stack gap="4x">
            <TextField
              label={t('commons:email')}
              value={email}
              onChange={(event) => {
                clearAuthError()
                setEmail(event.target.value)
              }}
            />
            <TextField
              label={t('commons:password')}
              type="password"
              value={password}
              onChange={(event) => {
                clearAuthError()
                setPassword(event.target.value)
              }}
              isInvalid={Boolean(authError)}
              errorMessage={authError}
            />
            <Link as={RouterLink} to="/forgot-password">
              {t('forgot_password_link')}
            </Link>
            <Button
              type="submit"
              isDisabled={!formIsValid()}
              isFullWidth
              onClick={() =>
                login({
                  variables: {
                    subUserLoginEmail: email,
                    subUserLoginPassword: password,
                  },
                })
              }
            >
              {t('log_in')}
            </Button>
          </Stack>
        </Stack>
      </FormWindow>
    </UnauthenticatedPageWrapper>
  )
}
